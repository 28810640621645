<template>
  <v-app>
    <AppBar v-if="!getShowFullView" />
    <v-main style="height: 100%">
      <ImageUpload />
      <router-view />
    </v-main>
    <BottomNavigation v-if="!getShowFullView" />
  </v-app>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app";

const { getShowFullView } = storeToRefs(useAppStore());
</script>
<script>
import AppBar from "./components/AppBar.vue";
import BottomNavigation from "./components/BottomNavigation.vue";
import ImageUpload from "./components/ImageUpload.vue";

export default {
  name: "App",
  components: {
    BottomNavigation,
    ImageUpload,
    AppBar,
  },
};
</script>
