<template>
  <v-overlay
    v-model="isUploadingComputed"
    scrim="#036358"
    class="d-flex justify-center align-center"
    persistent
  >
    <v-progress-circular
      v-if="!isCompressing"
      :size="150"
      :width="10"
      :model-value="getCurrentImageUploadPercentage"
      color="white"
    >
      uploading {{ currentUploading + 1 }} of {{ files.length }}
    </v-progress-circular>
    <v-progress-circular
      v-if="isCompressing"
      :size="150"
      :width="10"
      :model-value="compressionProgress"
      color="white"
      class="pa-2 text-center"
    >
      processing your image
    </v-progress-circular>
  </v-overlay>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app";
const { getCurrentImageUploadPercentage } = storeToRefs(useAppStore());
</script>

<script>
export default {
  props: {
    isUploading: Boolean,
    isCompressing: Boolean,
    files: Array,
    compressionProgress: Number,
    currentUploading: Number,
  },
  computed: {
    isUploadingComputed() {
      return this.isUploading;
    },
  },
};
</script>
