<template>
  <div>
    <v-infinite-scroll mode="manual" @load="load">
      <div v-if="PhotosArray.length == 0">
        <v-row
          class="fill-height ma-0 text-center"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey-lighten-5"
          ></v-progress-circular>
        </v-row>
      </div>
      <div class="d-flex flex-wrap" v-else>
        <v-col
          v-for="(item, index) in PhotosArray"
          :key="item.PK"
          class="pa-1"
          :cols="cols"
          @click="openInFullView(index)"
        >
          <v-lazy :options="{ threshold: 0.5 }" transition="fab-transition">
            <div v-if="item.assetType == 'VIDEO'" class="image-container">
              <v-img
                :src="imageSource(item)"
                aspect-ratio="1"
                cover
                class="bg-grey-lighten-2"
                style="opacity: 0.6"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey-lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-icon class="iconCenter"> mdi-play-circle-outline </v-icon>
            </div>

            <v-img
              v-else
              :src="imageSource(item)"
              aspect-ratio="1"
              cover
              class="bg-grey-lighten-2"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey-lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-lazy>
        </v-col>
      </div>
    </v-infinite-scroll>

    <!-- show fullscreen as dialog fullscreen for selected index -->
    <FullscreenPreview v-if="showFullView" />
  </div>
</template>

<script setup>
import { useAppStore } from "@/store/app";
</script>

<script>
import FullscreenPreview from "@/components/FullscreenPreview.vue";

export default {
  components: {
    FullscreenPreview,
  },
  props: {
    PhotosArray: {
      type: Array,
      required: true,
    },
    load: {
      type: Function,
      required: true,
    },
    getPhotoUrlsFun: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cols: 3,
    };
  },
  methods: {
    imageSource(item) {
      if (item.assetType == "VIDEO") {
        return item.thumbnailFileName;
      } else {
        return item.thumbnailFileName ?? item.s3Key;
      }
    },
    openInFullView(index) {
      console.log("openInFullView", index);
      const store = useAppStore();
      store.$state.currentPhotoIndexToPreview = index;
      store.$state.showFullView = true;
      //navgate to fullscreen
      store.$state.fullScreenPropsPhotosArray = this.PhotosArray;
      store.$state.fullScreenPropsgetPhotoUrlsFun = this.getPhotoUrlsFun;
      // this.$router.push("/fullscreen");
    },
    getColumnCount() {
      if (window.innerWidth <= 450) {
        return 6;
      } else if (window.innerWidth <= 800) {
        return 4;
      } else if (window.innerWidth <= 1000) {
        return 3;
      } else {
        return 2;
      }
    },
  },
  computed: {
    showFullView: {
      get() {
        const store = useAppStore();
        return store.$state.showFullView;
      },
      set() {
        const store = useAppStore();
        store.showFullView = !store.$state.showFullView;
      },
    },
  },
  mounted() {
    // Set initial value based on window width
    this.cols = this.getColumnCount();

    // Watch for window resize to update column count
    window.addEventListener("resize", () => {
      this.cols = this.getColumnCount();
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", () => {
      this.cols = this.getColumnCount();
    });
  },
};
</script>

<style scoped>
.image-container {
  position: relative;
}

.iconCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px; /* Adjust the font size as per your requirement */
}

video {
  width: 100%;
  height: auto;
  max-height: 90%;
}

.video-content {
  height: 90vh;
}
</style>
