<template>
  <div>
    <div class="d-flex mx-2">
      <v-icon @click="navBack"> mdi-arrow-left </v-icon>
      <v-spacer />
      <v-span class="mx-2"> Pictures of </v-span>
      <span v-if="displayName.length == 0">
        {{ person.displayName }}
      </span>
      <span v-else>
        {{ displayName }}
      </span>
      <v-spacer />
      <!-- add edit icon -->
      <v-icon @click="openEditNameDialog" class="mx-1"> mdi-pencil </v-icon>
    </div>
    <InfiniteScroll
      :load="load"
      :PhotosArray="getPhotosByPerson"
      getPhotoUrlsFun="getPhotoUrlsBasedOnPerson"
    />
    <!-- a dialog with text field, close and save button -->
    <v-dialog v-model="openDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Name</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="displayName"
            label="Display Name"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="openDialog = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveName"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app";
const { getPhotosByPerson } = storeToRefs(useAppStore());
</script>
<script>
import InfiniteScroll from "@/components/InfiniteScroll.vue";
import { updateDisplayName } from "@/services/dynamodb";
export default {
  name: "PersonFolderView",
  props: ["person"],
  components: {
    InfiniteScroll,
  },
  data() {
    return { openDialog: false, displayName: "" };
  },
  methods: {
    navBack() {
      const store = useAppStore();
      store.resetCurrentSelectedPerson();
    },
    async load({ done }) {
      console.log("load");
      this.loadNewImages = false;
      const res = await this.getPhotoUrlsBasedOnPerson();
      done(res);
    },
    async getPhotoUrlsBasedOnPerson() {
      const store = useAppStore();
      return await store.getPhotoUrlsBasedOnPerson();
    },
    openEditNameDialog() {
      this.openDialog = true;
    },
    async saveName() {
      console.log("name updated: ", this.displayName);
      if (this.displayName != this.person.displayName) {
        console.log("updated person: ", this.person);
      }
      let updateRes = await updateDisplayName(
        this.person.name,
        this.displayName
      );
      console.log(updateRes);
      this.openDialog = false;
      const store = useAppStore();
      store.$state.allDetectedPersons = [];
      store.$state.LastEvaluatedKeyByPersons = null;
      await store.setAllDetectedPersons();
    },
  },
  mounted: async function () {
    await this.getPhotoUrlsBasedOnPerson();
    this.displayName = this.person.displayName;
  },
};
</script>
