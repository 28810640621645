const AWS = window.AWS;

import { Auth } from "aws-amplify";
import logger from "@/utilities/logger";

const REGION = import.meta.env.VITE_APP_AWS_REGION;
const TABLE_NAME = import.meta.env.VITE_APP_DDB_TABLENAME;

const updateAWSConfig = async () => {
  let currentCredentials = await Auth.currentCredentials();

  AWS.config.update({
    accessKeyId: currentCredentials.accessKeyId,
    secretAccessKey: currentCredentials.secretAccessKey,
    sessionToken: currentCredentials.sessionToken,
    region: REGION,
  });
};

export const dynamoDBQuery = async (params) => {
  let currentCredentials = await Auth.currentCredentials();

  AWS.config.update({
    accessKeyId: currentCredentials.accessKeyId,
    secretAccessKey: currentCredentials.secretAccessKey,
    sessionToken: currentCredentials.sessionToken,
    region: REGION,
  });

  const ddb = new AWS.DynamoDB.DocumentClient();

  return new Promise((resolve, reject) => {
    ddb.query(params, function (err, data) {
      if (err) {
        logger.error("Error in dbQuery", err);
        reject(false);
      } else {
        resolve(data);
      }
    });
  });
};

export const putItem = async (params) => {
  let currentCredentials = await Auth.currentCredentials();

  AWS.config.update({
    accessKeyId: currentCredentials.accessKeyId,
    secretAccessKey: currentCredentials.secretAccessKey,
    sessionToken: currentCredentials.sessionToken,
    region: REGION,
  });

  const ddb = new AWS.DynamoDB.DocumentClient();

  return new Promise((resolve, reject) => {
    ddb.put(params, function (err, data) {
      if (err) {
        logger.error("Error in putItem", err);
        reject(false);
      } else {
        logger.log("putItem success", data);
        resolve(true);
      }
    });
  });
};

export const updateItem = async (params) => {
  const ddb = new AWS.DynamoDB.DocumentClient();
  return new Promise((resolve, reject) => {
    ddb.update(params, function (err, data) {
      if (err) {
        logger.error("Error in updateItem", err);
        reject(false);
      }
      resolve(data);
    });
  });
};
export const dynamoDBBatchGet = async (params) => {
  let currentCredentials = await Auth.currentCredentials();

  AWS.config.update({
    accessKeyId: currentCredentials.accessKeyId,
    secretAccessKey: currentCredentials.secretAccessKey,
    sessionToken: currentCredentials.sessionToken,
    region: REGION,
  });
  const ddb = new AWS.DynamoDB.DocumentClient();
  return new Promise((resolve, reject) => {
    ddb.batchGet(params, function (err, data) {
      if (err) {
        logger.error("Error in dbBatchGet", err);
        reject(false);
      } else {
        resolve(data);
      }
    });
  });
};

export const getCurrentLimit = async (userEmail) => {
  await updateAWSConfig();
  const params = {
    TableName: TABLE_NAME,
    KeyConditionExpression: "PK = :PK",
    ExpressionAttributeValues: {
      ":PK": `LIMIT#${userEmail}`,
    },
    IndexName: "PK-limit-index",
  };
  const data = await dynamoDBQuery(params);
  console.log("getCurrentLimit: ", data);
  // return SK
  return data.Items[0].limit;
};

export const setCurrentLimit = async (userEmail, limit) => {
  await updateAWSConfig();

  const params = {
    TableName: TABLE_NAME,
    Item: {
      PK: `LIMIT#${userEmail}`,
      SK: userEmail,
      limit,
    },
  };
  return await putItem(params);
};

export const updateDisplayName = async (name, displayName) => {
  // update where PK PERSON#name and SK name set displayName
  await updateAWSConfig();

  const params = {
    TableName: TABLE_NAME,
    Key: {
      PK: `PERSON#${name}`,
      SK: name,
    },
    UpdateExpression: "set displayName = :displayName",
    ExpressionAttributeValues: {
      ":displayName": displayName,
    },
  };
  return await updateItem(params);
};
