const AWS = window.AWS;

import { Auth } from "aws-amplify";
import logger from "@/utilities/logger";

const REGION = import.meta.env.VITE_APP_AWS_REGION;
import { useAppStore } from "@/store/app";

const updateAWSConfig = async () => {
  let currentCredentials = await Auth.currentCredentials();

  AWS.config.update({
    accessKeyId: currentCredentials.accessKeyId,
    secretAccessKey: currentCredentials.secretAccessKey,
    sessionToken: currentCredentials.sessionToken,
    region: REGION,
  });
};

export const uploadToS3 = async (params) => {
  await updateAWSConfig();
  const s3 = new AWS.S3();
  const store = useAppStore();
  return new Promise((resolve, reject) => {
    s3.upload(params, function (err, data) {
      if (err) {
        console.log("Error in uploadToS3", err);
        logger.error("Error in uploadToS3", err);
        reject(false);
      }
      resolve(data);
    }).on("httpUploadProgress", function (progress) {
      let progressPercentage = Math.round(
        (progress.loaded / progress.total) * 100
      );
      console.log("progressPercentage: ", progressPercentage);
      store.$state.currentImageUploadPercentage = progressPercentage;
    });
  });
};
