<template>
  <v-bottom-navigation v-model="value" grow>
    <v-btn
      class="pa-1"
      v-for="navButton in navButtons"
      :key="navButton.text"
      :to="navButton.route"
    >
      <v-icon class="ma-0 pa-0"> {{ navButton.icon }} </v-icon>
      {{ navButton.text }}
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
import logger from "@/utilities/logger";
export default {
  data: () => ({
    value: 0,
    navButtons: [
      { text: "Home", icon: "mdi-home-outline", route: "/" },
      {
        text: "Uploaded By",
        icon: "mdi-folder-account-outline",
        route: "/folder",
      },
      {
        text: "People",
        icon: "mdi-account-multiple-outline",
        route: "/persons",
      },
      {
        text: "Profile",
        icon: "mdi-account-outline",
        route: "/profile",
      },
    ],
  }),
  // watch for router changes and update the value
  watch: {
    $route() {
      logger.log("route changed", this.$route);
      let routeMapping = {
        "/": 0,
        "/folder": 1,
        "/profile": 2,
      };
      this.value = routeMapping[this.$route.path];
    },
  },
};
</script>
