<template>
  <div>
    <div class="d-flex justify-space-between mx-2">
      <v-icon @click="navBack"> mdi-arrow-left </v-icon>
      Uploaded by {{ username.split("@")[0] }}
    </div>
    <InfiniteScroll
      :load="load"
      :PhotosArray="getPhotosUploadedBy"
      getPhotoUrlsFun="getPhotosUploadedBy"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app";
const { getPhotosUploadedBy } = storeToRefs(useAppStore());
</script>
<script>
import InfiniteScroll from "@/components/InfiniteScroll.vue";
export default {
  name: "UserFolderView",
  props: ["username"],
  components: {
    InfiniteScroll,
  },
  methods: {
    navBack() {
      const store = useAppStore();
      store.resetCurrentSelectedUserToViewInUploadedBy();
    },
    async load({ done }) {
      console.log("load");
      this.loadNewImages = false;
      const res = await this.getPhotoUrlsBasedOnUploadedBy();
      done(res);
    },
    async getPhotoUrlsBasedOnUploadedBy() {
      const store = useAppStore();
      return await store.getPhotoUrlsBasedOnUploadedBy();
    },
  },
  mounted: async function () {
    await this.getPhotoUrlsBasedOnUploadedBy();
  },
};
</script>
