const COGNITO_REGION = import.meta.env.VITE_APP_AWS_REGION;

const amplifyConfig = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: import.meta.env.VITE_APP_IDENTITY_POOL_ID,

    // REQUIRED - Amazon Cognito Region
    region: COGNITO_REGION,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: COGNITO_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: import.meta.env.VITE_APP_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: import.meta.env.VITE_APP_APP_CLIENT_ID,
    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: import.meta.env.VITE_APP_COGNITO_DOMAIN,
      scope: ["phone", "email", "openid"],
      redirectSignIn: import.meta.env.VITE_APP_REDIRECT_SIGN_IN_URL,
      redirectSignOut: import.meta.env.VITE_APP_REDIRECT_SIGN_OUT_URL,
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};

export default amplifyConfig;
