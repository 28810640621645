<template>
  <div class="floatingActionButton">
    <v-btn icon="mdi-plus" color="primary" size="x-large">
      <v-icon> mdi-plus </v-icon>
      <v-menu activator="parent">
        <v-list>
          <v-list-item @click="selectImage">
            <v-list-item-title>Image</v-list-item-title>
          </v-list-item>
          <v-list-item @click="selectVideo">
            <v-list-item-title>Video</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn>
    <v-file-input
      accept="image/jpg, image/jpeg"
      v-show="false"
      ref="fileInput"
      v-model="files"
      @change="onFileChange"
      multiple
      style="display: none"
    ></v-file-input>
    <v-file-input
      accept="video/mp4,video/x-m4v,video/*"
      v-show="false"
      ref="fileInputVideo"
      v-model="files"
      @change="onFileChange"
      style="display: none"
    ></v-file-input>

    <v-dialog
      v-model="dialog"
      fullscreen
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card v-if="isImageUpload">
        <v-responsive
          class="fill-height"
          @touchstart="onTouchStart"
          @touchmove="onTouchMove"
          @touchend="onTouchEnd"
        >
          <UploadProgressIndicator
            :isUploading="isUploading"
            :isCompressing="isCompressing"
            :files="files"
            :compressionProgress="compressionProgress"
            :currentUploading="currentUploading"
          />
          <v-img :src="imagePreview" height="100%" contain>
            <template class="d-flex flex-column align-center">
              <div class="mt-auto" v-if="files.length > 1">
                <div class="d-flex justify-center align-center">
                  <!-- show small previews of all images present in size 10 and hightlight the selected image  -->
                  <v-card
                    :height="heightOfThumbnail(index)"
                    :width="heightOfThumbnail(index)"
                    v-for="(url, index) in urls"
                    :key="url"
                    elevation="0"
                    color="transparent"
                    class="ml-1"
                  >
                    <v-img :src="url" @click="deleteImage(index)" cover>
                      <div
                        v-if="index == currentPreview"
                        class="d-flex justify-center align-center"
                      >
                        <v-icon color="white" size="large" class="ma-auto">
                          mdi-delete-outline
                        </v-icon>
                      </div>
                    </v-img>
                  </v-card>
                </div>
              </div>
            </template>
          </v-img>
          <v-card
            width="100%"
            style="position: fixed; bottom: 0; background: rgba(0, 0, 0, 0.3)"
            class="pa-2"
          >
            <!-- add upload button and cancel button as icons -->
            <div class="d-flex">
              <v-btn icon @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-spacer />
              <v-btn icon @click="uploadImage" v-if="!isUploading">
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-responsive>
      </v-card>
      <v-card v-if="!isImageUpload">
        <UploadProgressIndicator
          :isUploading="isUploading"
          :isCompressing="isCompressing"
          :files="files"
          :compressionProgress="compressionProgress"
          :currentUploading="currentUploading"
        />
        <video
          class="my-auto video-js"
          id="video-preview"
          ref="videoPreview"
          controls
          autoplay
          muted
        ></video>
        <v-card
          width="100%"
          style="background: rgba(0, 0, 0, 0.3)"
          class="pa-2"
        >
          <div class="d-flex">
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn icon @click="uploadImage" v-if="!isUploading">
              <v-icon>mdi-upload</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script setup>
import { useAppStore } from "@/store/app";
import UploadProgressIndicator from "./UploadProgressIndicator.vue";
</script>

<script>
import { uploadToS3 } from "@/services/s3";
import { putItem, getCurrentLimit, setCurrentLimit } from "@/services/dynamodb";
import logger from "@/utilities/logger";
import { ulid } from "ulidx";

import imageCompression from "browser-image-compression";

import { useToast } from "vue-toast-notification";

export default {
  name: "ImageUpload",
  data: () => ({
    dialog: false,
    files: null,
    urls: null,
    photoRules: [
      (v) => !v || v.size < 5000000 || "Image should be less than 5MB",
    ],
    imagePreview: null,
    currentPreview: null,
    touchStartX: 0,
    touchEndX: 0,
    translateX: 0,
    isUploading: false,
    currentUploading: 0,
    compressionProgress: 0,
    isCompressing: false,
    acceptVideo: "video/mp4,video/x-m4v,video/*",
    acceptImage: "image/jpg, image/jpeg",
    isImageUpload: true,
    player: null,
  }),
  computed: {
    fileInputAcceptType() {
      return this.isImageUpload ? this.acceptImage : this.acceptVideo;
    },
  },
  methods: {
    captureImageFromVideo() {
      const video = this.$refs.videoPreview;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      const dataUrl = canvas.toDataURL("image/jpeg");
      return this.dataURItoBlob(dataUrl);

      // const img = new Image();
      // img.src = canvas.toDataURL("image/jpeg");

      // const imageContainer = this.$refs.imageContainer;
      // imageContainer.innerHTML = "";
      // imageContainer.appendChild(img);
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to a typed array
      let ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },
    imageThumbnailElevation(index) {
      return index === this.currentPreview ? 10 : 0;
    },
    heightOfThumbnail(index) {
      return index === this.currentPreview ? 50 : 40;
    },
    openDialog() {
      this.dialog = true;
    },
    selectImage() {
      this.isImageUpload = true;
      // click fileInput
      this.$refs.fileInput.click();
    },
    selectVideo() {
      this.isImageUpload = false;
      // click fileInput
      this.$refs.fileInputVideo.click();
    },
    previewVideo() {
      const reader = new FileReader();
      reader.readAsDataURL(this.files[0]);
      reader.onload = () => {
        this.$refs.videoPreview.src = reader.result;
      };
    },
    onFileChange() {
      if (this.files.length == 0) {
        return;
      }
      if (this.isImageUpload) {
        const VITE_APP_BATCH_UPLOAD_LIMIT = import.meta.env
          .VITE_APP_BATCH_UPLOAD_LIMIT;
        if (this.files.length > VITE_APP_BATCH_UPLOAD_LIMIT) {
          this.files = [];
          this.$toast.error(
            `You can only upload ${VITE_APP_BATCH_UPLOAD_LIMIT} images`
          );
          return;
        }
        // set all urls
        this.urls = [];
        for (const element of this.files) {
          this.urls.push(URL.createObjectURL(element));
        }
        this.currentPreview = 0;
        this.imagePreview = this.urls[this.currentPreview];
      } else {
        const fileSize = (this.files[0].size / (1024 * 1024)).toFixed(0);
        console.log("fileSize: ", typeof fileSize);
        console.log(parseInt(fileSize) > 110);
        if (parseInt(fileSize) > 110) {
          console.log("file size is greater");
          this.dialog = false;
          this.$toast.error(`Your video needs to be less than 100Mb`);
          return;
        }
        this.previewVideo();
      }
      this.dialog = true;
    },
    async uploadImage() {
      this.isUploading = true;
      const store = useAppStore();
      const toast = useToast();
      // upload all images to s3 bucket
      for (let index = 0; index < this.files.length; index++) {
        const element = this.files[index];
        this.currentUploading = index;
        const currentLimit = await getCurrentLimit(store.$state.userEmail);
        console.log("currentLimit: ", currentLimit);
        if (currentLimit <= 0) {
          this.isUploading = false;
          this.dialog = false;
          this.$toast.error(
            "You have reached upload limit. Contact event organizer."
          );
          return;
        }
        store.$state.currentImageUploadPercentage = 0;
        //set current epoch time as uuid
        const epoch = Date.now();
        const uuid = ulid();
        const fileName = uuid + "." + element.name.split(".").pop();
        const file = new File([element], fileName, {
          type: element.type,
        });
        let compressedFile;
        let thumbnailFileName = `thumbnail/${uuid}.jpeg`;
        if (this.isImageUpload) {
          //compress file using Imagecompression
          this.isCompressing = true;
          const imageCompressionOption = {
            maxSizeMB: 3,
            alwaysKeepResolution: true,
            onProgress: (data) => {
              this.compressionProgress = data;
            },
          };
          compressedFile = await imageCompression(file, imageCompressionOption);
          console.log(`original size ${file.size / 1024 / 1024} MB`);
          console.log(
            `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
          ); // smaller than maxSizeMB
          this.isCompressing = false;
        } else {
          compressedFile = file;
          let thumbnail = this.captureImageFromVideo();

          const s3Params = {
            Bucket: import.meta.env.VITE_APP_S3_BUCKET_NAME,
            Key: thumbnailFileName,
            Body: thumbnail,
          };
          logger.log("s3Params: ", s3Params);
          const thumbnailUpload = await uploadToS3(s3Params);
          if (!thumbnailUpload) {
            logger.error("failed to upload image. please try again later");
            toast.error("failed to upload image. please try again later", {
              position: "top-right",
            });
            return;
          }
        }
        //set folder name
        const folderName = this.isImageUpload ? "originals" : "orignalVideos";
        const s3Params = {
          Bucket: import.meta.env.VITE_APP_S3_BUCKET_NAME,
          Key: `${folderName}/${fileName}`,
          Body: compressedFile,
        };
        logger.log("s3Params: ", s3Params);
        const data = await uploadToS3(s3Params);
        if (!data) {
          logger.error("failed to upload image. please try again later");
          toast.error("failed to upload image. please try again later", {
            position: "top-right",
          });
        }
        logger.log(data);
        const ddbParamsItem = {
          TableName: import.meta.env.VITE_APP_DDB_TABLENAME,
          Item: {
            PK: uuid,
            SK: `UPLOADED_BY#${store.$state.userEmail}`,
            uploadedBy: store.$state.userEmail,
            entityType: "IMAGE",
            s3Key: s3Params.Key,
            upload_datetime: epoch,
            assetType: this.isImageUpload ? "IMAGE" : "VIDEO",
            thumbnailFileName: this.isImageUpload ? null : thumbnailFileName,
          },
          ReturnConsumedCapacity: "TOTAL",
        };
        logger.log("ddbParamsItem: ", ddbParamsItem);
        const ddbRes = await putItem(ddbParamsItem);
        if (!ddbRes) {
          logger.error("failed to upload image. please try again later");
          toast.error("failed to upload image. please try again later", {
            position: "top-right",
          });
        }
        logger.log(ddbRes);
        //set current limit
        await setCurrentLimit(store.$state.userEmail, currentLimit - 1);
      }
      this.isUploading = false;
      this.dialog = false;
      toast.success("Uploaded", {
        position: "top-right",
      });
    },
    deleteImage(index) {
      logger.log("Delete image at index: ", index);
      // set current preview to next image if deleted image is not the last image
      if (index == this.urls.length - 1) {
        this.currentPreview = 0;
      }
      if (this.currentPreview > index) {
        this.currentPreview = this.currentPreview - 1;
      }
      //remove image from urls array
      this.urls.splice(index, 1);
      this.files.splice(index, 1);
      if (this.files.length == 0) {
        this.dialog = false;
      }
      this.imagePreview = this.urls[this.currentPreview];
    },
    closeDialog() {
      this.dialog = false;
      this.urls = [];
      this.files = [];
      this.currentPreview = 0;
    },
    prevImage() {
      if (this.currentPreview > 0) {
        this.currentPreview--;
        this.imagePreview = URL.createObjectURL(
          this.files[this.currentPreview]
        );
      }
    },
    nextImage() {
      if (this.currentPreview < this.files.length - 1) {
        this.currentPreview++;
        this.imagePreview = URL.createObjectURL(
          this.files[this.currentPreview]
        );
      }
    },
    onTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
    },
    onTouchMove(event) {
      this.touchEndX = event.touches[0].clientX;
      this.translateX = this.touchEndX - this.touchStartX;
    },
    onTouchEnd() {
      // Determine if it's a left or right swipe based on the distance
      const threshold = 50; // Adjust this value as needed
      if (this.translateX > threshold) {
        // Right swipe
        // Perform your action for a right swipe (e.g., show previous content)
        logger.log("right swipe");
        this.prevImage();
      } else if (this.translateX < -threshold) {
        // Left swipe
        // Perform your action for a left swipe (e.g., show next content)
        logger.log("left swipe");
        this.nextImage();
      }
      // Reset the translation
      this.translateX = 0;
    },
  },
  components: { UploadProgressIndicator },
};
</script>

<style scoped>
video {
  width: 100%;
  height: auto;
  max-height: 90%;
}

.video-content {
  height: 90vh;
}

.floatingActionButton {
  position: fixed;
  bottom: 70px;
  right: 20px;
  z-index: 2;
}
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}
</style>
