<template>
  <v-app-bar elevation="0">
    <v-img class="ma-1 mx-auto" max-width="100" src="@/assets/logo.png" />
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",
};
</script>
