<template>
  <div class="">
    <div v-if="isCurrentSelectedPersonsToViewEmpty">
      <div class="d-flex flex-wrap justify-space-around">
        <v-card
          @click="selectPerson(person)"
          elevation="0"
          class="ma-1"
          v-for="person in getAllDetectedPersons"
          :key="person.name"
        >
          <v-card-item class="ma-0 pa-0">
            <v-img :src="person.url" cover height="80" width="80"> </v-img>
          </v-card-item>
          <v-card-actions class="text-center">
            <v-spacer></v-spacer>
            {{ person.displayName }}
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </div>

      <div v-if="!getNoMoreImagesForPersonsDetected">
        <v-btn
          color="primary"
          class="ma-auto"
          @click="fetchPersons"
          v-if="!loading"
        >
          load more
        </v-btn>
        <v-btn color="primary" class="ma-auto" v-else> loading... </v-btn>
      </div>
    </div>
    <PersonFolderView
      v-if="!isCurrentSelectedPersonsToViewEmpty"
      :person="getCurrentSelecterPersonsToView"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app";
const {
  getAllDetectedPersons,
  getNoMoreImagesForPersonsDetected,
  getCurrentSelecterPersonsToView,
  isCurrentSelectedPersonsToViewEmpty,
} = storeToRefs(useAppStore());
</script>
<script>
import PersonFolderView from "@/views/PersonFolderView.vue";
export default {
  data() {
    return {
      persons: [],
      loading: false,
    };
  },
  components: { PersonFolderView },
  methods: {
    selectPerson(person) {
      const store = useAppStore();
      store.$state.currentSelecterPersonsToView = person;
    },
    async fetchPersons() {
      this.loading = true;
      const store = useAppStore();
      await store.setAllDetectedPersons();
      this.loading = false;
    },
  },
  async mounted() {
    await this.fetchPersons();
  },
  beforeUnmount() {
    const store = useAppStore();
    store.resetCurrentSelectedPerson();
  },
};
</script>
