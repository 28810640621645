/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from "./App.vue";

// Composables
import { createApp } from "vue";

// Plugins
import { registerPlugins } from "@/plugins";

import { Amplify } from "aws-amplify";
import amplifyConfig from "./aws-config";

// import NwImg from "nw-img-vue";
import ToastPlugin from "vue-toast-notification";
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-bootstrap.css";

Amplify.configure(amplifyConfig);

const app = createApp(App);

registerPlugins(app);

// app.use(NwImg);
app.use(ToastPlugin, {
  // One of the options
  position: "top-right",
});
app.mount("#app");
