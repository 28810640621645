<template>
  <div>
    <v-banner
      v-if="getNewImagesAvailable"
      lines="one"
      density="compact"
      class="loadNewBanner"
    >
      <template v-slot:text> New Images are available </template>

      <template v-slot:actions>
        <v-btn color="deep-purple-accent-4" @click="LoadNewImages">
          Refresh</v-btn
        >
      </template>
    </v-banner>
    <InfiniteScroll
      :load="load"
      :PhotosArray="getPhotosHome"
      getPhotoUrlsFun="getPhotoUrls"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app";
const { getPhotosHome, getNewImagesAvailable } = storeToRefs(useAppStore());
</script>
<script>
import InfiniteScroll from "@/components/InfiniteScroll.vue";
export default {
  data() {
    return {
      loadNewImages: false,
      interval: null,
    };
  },
  components: {
    InfiniteScroll,
  },
  methods: {
    async LoadNewImages() {
      const store = useAppStore();
      store.$state.newImagesAvailable = false;
      store.$state.LastEvaluatedKey = null;
      store.$state.noMoreImages = false;
      await this.getPhotoUrls();
      //scroll to top
      window.scrollTo(0, 0);
    },
    async load({ done }) {
      console.log("load");
      this.loadNewImages = false;
      const res = await this.getPhotoUrls();
      done(res);
    },
    async getPhotoUrls() {
      const store = useAppStore();
      return await store.getPhotoUrls();
    },
  },
  mounted: async function () {
    await this.getPhotoUrls();

    const store = useAppStore();
    // run checkNewImagesAvailable on interval of 5 minutes
    this.interval = setInterval(async () => {
      await store.checkNewImagesAvailable();
    }, 3 * 60 * 1000);
  },
  unmounted() {
    if (!this.interval) {
      console.log("clearing timeout");
      clearTimeout(this.interval);
    }
  },
};
</script>

<style scoped>
.loadNewBanner {
  position: fixed;
  z-index: 1;
}
.loadNew {
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
}
#photos {
  /* Prevent vertical gaps */
  line-height: 0;

  -webkit-column-count: 5;
  -webkit-column-gap: 0px;
  -moz-column-count: 5;
  -moz-column-gap: 0px;
  column-count: 5;
  column-gap: 0px;
}

#photos img {
  /* Just in case there are inline attributes */
  width: 100% !important;
  height: auto !important;
}

@media (max-width: 1200px) {
  #photos {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}
@media (max-width: 1000px) {
  #photos {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}
@media (max-width: 800px) {
  #photos {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}
@media (max-width: 450px) {
  #photos {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}
</style>
