const WEB_EVENT_URL = import.meta.env.VITE_APP_WEB_EVENT_URL;

const publishEvent = async (event) => {
  // make a post call to WEB_EVENT_URL with event in body
  try {
    const response = await fetch(WEB_EVENT_URL + "event", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(event),
    });
    console.log("publishEvent: ", response);
  } catch (e) {
    console.error("error in publishing event: ", e);
  }
};

export { publishEvent };
