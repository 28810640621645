<template>
  <div>
    <div
      class="grid-container ma-1"
      v-if="getCurrentSelectedUserToViewInUploadedBy.length == 0"
    >
      <v-card
        elevation="0"
        class="d-flex flex-column justify-center align-center grid-item ma-1 pa-1"
        v-for="user in getUsersToShowInUploadedBy"
        :key="user"
        @click="navToSubroute(user)"
      >
        <v-img src="@/assets/folder.png" width="70" height="70"> </v-img>
        <div class="user-name">{{ user.split("@")[0] }}</div>
      </v-card>
      <v-card elevation="0" height="100"> </v-card>
    </div>
    <UserFolderView
      :username="getCurrentSelectedUserToViewInUploadedBy"
      v-if="getCurrentSelectedUserToViewInUploadedBy.length > 0"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app";
const { getUsersToShowInUploadedBy, getCurrentSelectedUserToViewInUploadedBy } =
  storeToRefs(useAppStore());
</script>

<script>
import UserFolderView from "@/views/UserFolderView.vue";
export default {
  components: { UserFolderView },
  methods: {
    navToSubroute(user) {
      console.log("user: ", user);
      const store = useAppStore();
      store.$state.currentSelectedUserToViewInUploadedBy = user;
    },
  },
  mounted: async function () {
    const store = useAppStore();
    await store.setUsersToShowInUploadedBy();
  },
  beforeUnmount() {
    const store = useAppStore();
    store.resetCurrentSelectedUserToViewInUploadedBy();
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(150px, 1fr)
  ); /* Automatically arrange columns */
  gap: 10px; /* Adjust the gap between grid items as needed */
}

.grid-item {
  text-align: center;
}
.user-name {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add an ellipsis (...) for long text */
  max-width: 100%; /* Ensure the text doesn't exceed the container's width */
}
</style>
