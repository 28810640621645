<template>
  <div class="text-center">
    <!-- show avatar with first letter of user email -->
    <v-avatar color="primary" size="x-large">
      {{ getUserEmail.slice(0, 1).toUpperCase() }}
    </v-avatar>

    <div class="ma-2">
      {{ getUserEmail }}
    </div>
    <v-btn @click="signout" color="secondary" class="ma-2"> Logout </v-btn>
    <v-card>
      <v-tabs bg-color="blue-grey-lighten-5" center-active>
        <v-tab>Uploads</v-tab>
      </v-tabs>
      <v-tabs-items>
        <v-tab-item>
          <InfiniteScroll
            :load="load"
            :PhotosArray="getPhotosUploadedBy"
            getPhotoUrlsFun="getPhotosUploadedBy"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app";
const { getUserEmail, getPhotosUploadedBy } = storeToRefs(useAppStore());
</script>
<script>
import InfiniteScroll from "@/components/InfiniteScroll.vue";
import { Auth } from "aws-amplify";

export default {
  name: "UserProfile",
  components: {
    InfiniteScroll,
  },
  methods: {
    async signout() {
      try {
        await Auth.signOut();
      } catch (e) {
        console.log(e);
      }
    },
    async load({ done }) {
      console.log("load");
      this.loadNewImages = false;
      const res = await this.getPhotoUrlsBasedOnUploadedBy();
      done(res);
    },
    async getPhotoUrlsBasedOnUploadedBy() {
      const store = useAppStore();
      return await store.getPhotoUrlsBasedOnUploadedBy();
    },
  },
  mounted: async function () {
    await this.getPhotoUrlsBasedOnUploadedBy();
  },
  beforeMount() {
    const store = useAppStore();
    store.$state.currentSelectedUserToViewInUploadedBy = store.$state.userEmail;
  },
  beforeUnmount() {
    const store = useAppStore();
    store.resetCurrentSelectedUserToViewInUploadedBy();
  },
};
</script>
