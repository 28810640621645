<template>
  <v-dialog v-model="showFullView" fullscreen>
    <v-overlay
      v-model="fetchingNewImages"
      class="d-flex justify-center align-center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
    <v-card
      @touchstart="onTouchStart"
      @touchmove="onTouchMove"
      @touchend="onTouchEnd"
    >
      <!-- show close button at top right -->
      <v-card-actions class="previewAction">
        <v-btn icon class="" @click="closeFullScreen" color="black">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="
            getFullScreenPropsPhotosArray[getCurrentPhotoIndexToPreview]
              .assetType != 'VIDEO'
          "
          @click="downloadImage"
          icon="mdi-download"
          size="x-large"
        ></v-btn>
        <!-- <v-menu location="start">
      <template v-slot:activator="{ props }">
        <v-icon v-bind="props">mdi-dots-vertical</v-icon>
      </template>

      <v-list>
        <v-list-item>
          <v-list-item-title @click="downloadImage"
            >Download</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu> -->
      </v-card-actions>
      <video
        v-if="
          getFullScreenPropsPhotosArray[getCurrentPhotoIndexToPreview]
            .assetType == 'VIDEO'
        "
        :src="
          getFullScreenPropsPhotosArray[getCurrentPhotoIndexToPreview].s3Key
        "
        controls
      ></video>
      <v-img
        v-else
        :src="
          getFullScreenPropsPhotosArray[getCurrentPhotoIndexToPreview].s3Key
        "
        contain
        class=""
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey-lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <div class="text-center scrollUpIcon" v-if="!sheet">
        <v-icon @click="sheet = !sheet"> mdi-equal </v-icon>
      </div>
      <div class="bottomSheetClass" v-if="sheet">
        <div class="text-center" v-if="sheet">
          <v-icon @click="sheet = !sheet"> mdi-equal </v-icon>
        </div>

        <v-bottom-sheet
          v-model="sheet"
          v-if="sheet"
          inset
          close-on-back="true"
          close-on-content-click="true"
        >
          <v-card class="text-center rounded-1" height="100" variant="tonal">
            <v-card-text class="d-flex">
              <div
                v-for="person in getPersonsDetected[
                  getFullScreenPropsPhotosArray[getCurrentPhotoIndexToPreview]
                    .PK
                ]"
                :key="person.name"
              >
                <v-img :src="person.url" height="60" width="60"> </v-img>
                {{ person.displayName }}
              </div>
            </v-card-text>
          </v-card>
        </v-bottom-sheet>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app";

const {
  getCurrentPhotoIndexToPreview,
  // getShowFullView,
  getPersonsDetected,
  getFullScreenPropsPhotosArray,
  // getFullScreenPropsgetPhotoUrlsFun,
} = storeToRefs(useAppStore());
</script>

<script>
import logger from "@/utilities/logger";

import { publishEvent } from "@/services/web_events";

export default {
  data() {
    return {
      fetchingNewImages: false,
      sheet: false,
    };
  },
  computed: {
    showFullView: {
      get() {
        const store = useAppStore();
        return store.$state.showFullView;
      },
      set() {
        const store = useAppStore();
        store.showFullView = !store.$state.showFullView;
      },
    },
  },
  // props: {
  //   PhotosArray: {
  //     type: Array,
  //     required: true,
  //   },
  //   getPhotoUrlsFun: {
  //     type: String,
  //     required: true,
  //   },
  // },
  mounted() {
    const store = useAppStore();
    if (!store.$state.showFullView) {
      this.$router.push("/");
    }
    store.$subscribe((mutation) => {
      const key = mutation.events.key;
      if (key === "currentPhotoIndexToPreview") {
        this.sheet = false;
      }
    });
  },
  beforeUnmount() {
    console.log("before unmount");
    const store = useAppStore();
    store.$state.showFullView = false;
    this.sheet = false;
  },
  methods: {
    closeFullScreen() {
      this.sheet = false;
      const store = useAppStore();
      store.$state.showFullView = false;
    },
    onTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
    },
    onTouchMove(event) {
      this.touchEndX = event.touches[0].clientX;
      this.translateX = this.touchEndX - this.touchStartX;
    },
    async onTouchEnd(event) {
      const store = useAppStore();
      // Determine if it's a left or right swipe based on the distance
      const threshold = 50; // Adjust this value as needed
      if (this.translateX > threshold) {
        // Right swipe
        // Perform your action for a right swipe (e.g., show previous content)
        logger.log("right swipe");
        if (store.$state.currentPhotoIndexToPreview === 0) {
          return;
        } else {
          store.$state.currentPhotoIndexToPreview--;
        }
      } else if (this.translateX < -threshold) {
        // Left swipe
        // Perform your action for a left swipe (e.g., show next content)
        logger.log("left swipe");
        //check if image is the last one in the array
        logger.log(
          "getFullScreenPropsPhotosArray.length: ",
          store.$state.fullScreenPropsPhotosArray.length
        );
        logger.log(
          "currentPhotoIndexToPreview: ",
          store.$state.currentPhotoIndexToPreview
        );
        if (
          store.$state.currentPhotoIndexToPreview ===
          store.$state.fullScreenPropsPhotosArray.length - 1
        ) {
          this.fetchingNewImages = true;
          await store[store.$state.fullScreenPropsgetPhotoUrlsFun];
          this.fetchingNewImages = false;
          //check if image is the last one in the array
          if (
            store.$state.currentPhotoIndexToPreview ===
            store.$state.fullScreenPropsPhotosArray.length - 1
          ) {
            store.$state.currentPhotoIndexToPreview = 0;
          }
        } else {
          store.$state.currentPhotoIndexToPreview++;
        }
      }

      // Reset the translation
      this.translateX = 0;

      //handle bottom to top gesture
      const endY = event.changedTouches[0].clientY;
      const deltaY = endY - this.startY;

      if (deltaY < -50) {
        // Swipe detected from bottom to top
        this.sheet = true;
      }
      // swipe detect for top to bottom
      if (deltaY > 50) {
        // Swipe detected from top to bottom
        this.sheet = false;
      }
    },
    async downloadImage() {
      const store = useAppStore();
      const imageUrl =
        store.$state.fullScreenPropsPhotosArray[
          store.$state.currentPhotoIndexToPreview
        ].s3Key;
      //get filename from url
      // const fileName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
      logger.log("imageUrl: ", imageUrl);
      try {
        // let downloadres = await this.downloadFromUrl(imageUrl);
        // console.log("downloadres: ", downloadres);

        // // Create a local object URL for the blob
        // const blobUrl = URL.createObjectURL(downloadres);

        // // Create a link element
        // const link = document.createElement("a");

        // // Set the href attribute with the object URL
        // link.href = blobUrl;

        // // Set the download attribute with the desired filename
        // link.download = "downloaded_file";

        // // Append the link to the document
        // document.body.appendChild(link);

        // // Programmatically click the link to trigger the download
        // link.click();

        // // Remove the link from the document
        // document.body.removeChild(link);

        // // Release the object URL to free up resources
        // URL.revokeObjectURL(blobUrl);

        //downlaod the imageurl by openeing in new tab
        const a = document.createElement("a");
        a.href = imageUrl;
        a.target = "_blank";
        a.download = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (e) {
        console.error("Error in downloading  ", e);
      }
      //publish event
      await publishEvent({ imageUrl, user: store.$state.userEmail });
    },
    async downloadFromUrl(url) {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    },
  },
  // watch sheet
  watch: {
    async sheet(val) {
      if (val) {
        const store = useAppStore();
        await store.setPersonsDetectedForImage();
      } else {
        const store = useAppStore();
        store.$state.personsDetected = {};
      }
    },
  },
};
</script>

<style scoped>
video {
  width: 100%;
  height: auto;
  max-height: 100%;
}

.video-content {
  height: 100vh;
}

.previewAction {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  background-color: rgba(225, 225, 225, 0.3);
}
.closeButton {
  /* set background color to black and opacity*/
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0.5;
  color: white;
}

.scrollUpIcon {
  position: fixed;
  /*align to center of bottom*/
  bottom: 20px;
  /*use translate to align it center of page*/
  transform: translateX(-50%);
  /*align to center of page*/
  left: 50%;
}

.bottomSheetClass {
  /*fix the position so that it looks like coming liding from down */
  position: fixed;
  /*align to center of bottom*/
  bottom: 0px;
  /*use translate to align it center of page*/
  transform: translateX(-50%);
  /*align to center of page*/
  left: 50%;
  width: 100%;
  background-color: white;
}

.popUpFullScreenDialog {
  height: 100vh;
  width: 100vw;
}
</style>
